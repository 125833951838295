import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalRef } from '@simpl/element-ng/modal';
import { IRoleSummary } from "src/app/modules/core/interfaces/auth/role-summary.interface";
import { AuthService } from "src/app/modules/core/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PermissionService } from "src/app/modules/core/services/permission.service";
import * as _ from "lodash";
import { Subject, takeUntil } from "rxjs";

const ADMIN_ROLE = {
	role: -1,
	name: "Admin",
	isDefault: false
};
@Component({
	selector: "app-change-role-view",
	templateUrl: "./change-role-view.component.html",
	styleUrls: ["./change-role-view.component.scss"]
})
export class ChangeRoleViewComponent implements OnInit, OnDestroy {
	hasAdminPermission: boolean;
	defaultRole: IRoleSummary;
	roles: IRoleSummary[];
	selected: IRoleSummary;
	recievedFrom: string;
  destory$ = new Subject();
	constructor(
		public readonly authService: AuthService,
		private readonly bsModalRef: ModalRef,
		private readonly activatedRoute: ActivatedRoute,
		readonly router: Router,
		private readonly permissionService: PermissionService
	) { }

	ngOnInit() {
		this.roles = _.cloneDeep(this.authService.getUser()?.roles);
		this.selected = this.authService.getActiveRole();
		this.defaultRole = this.authService.getDefaultRole();
	}

  ngOnDestroy(): void {
    this.destory$.next(true);
    this.destory$.complete()
  }

	handleClose() {
		this.bsModalRef.hide();
	}

	onSelectRole(roleData: IRoleSummary) {
		if (roleData.id !== -1) {
			this.selected = roleData;
		} else {
			if (this.selected.id !== -1 && !this.router.url.includes("admin")) {
				localStorage.setItem('roleChange', 'true');
				this.router.navigate(["admin"], { relativeTo: this.activatedRoute }).then(() => {
          this.destory$.next(true);
          this.destory$.complete();
        });
				this.bsModalRef.hide();
			}
		}

	}

	defaultRoleSelection(event: any) {
		for (const role of this.roles) {
			role.isDefault = false;
			if (role.id == this.selected.id) {
				role.isDefault = true;
			}
		}
		if (event.target.checked) {
			this.defaultRole = this.selected;
		} else {
			this.defaultRole = null;
		}
	}

	save() {
		if (this.recievedFrom === "resolver") {
			this.saveAndSetDefaultRole();
		} else {
			if (this.defaultRole?.id !== this.authService.getDefaultRole()?.id) {
				this.saveAndSetDefaultRole();
			} else if (this.selected.id !== this.authService.getActiveRole()) {
				this.saveRoleChange();
			}

		}
	}

	saveAndSetDefaultRole() {
		const formToBeSubmitted: any = {
			userDto: this.authService.getUser(),
			roleDtos: this.roles
		};

		this.authService.setUserDefaultRole(formToBeSubmitted).pipe(takeUntil(this.destory$)).subscribe(() => {
			this.authService.setActiveRole(this.selected);
			this.authService.setDefaultRole(this.selected.id);
			const link = this.selected.listOfScopes.find((scope) => scope.code == "SAD") || this.selected.listOfScopes.find((scope) => scope.category == "ADMIN") ? '/admin' : '/user'
			this.router.navigate([link], { relativeTo: this.activatedRoute });
			this.selected = this.authService.getActiveRole();
			this.defaultRole = this.authService.getDefaultRole();
			this.bsModalRef.hide();
		});
	}

	saveRoleChange() {
		this.authService.setActiveRole(this.selected);
		const link = this.selected.listOfScopes.find((scope) => scope.code == "SAD") || this.selected.listOfScopes.find((scope) => scope.category == "ADMIN") ? '/admin' : '/user'

		this.router.navigate([link], { relativeTo: this.activatedRoute });
		this.bsModalRef.hide();
	}

}
