import { Component, OnInit } from '@angular/core';
import { SettingsService } from './services/settings.service';
import { setTheme } from 'ngx-bootstrap/utils';
import { Localization } from './services/localization/localization.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SessionService } from './services/session.service';
import { AuthService } from './modules/core/services/auth.service';
import { themeElement, themeSupport } from '@simpl/charts-ng';

themeSupport.setDefault(themeElement);

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	selectedValue = this.settingsService.language;
	cookieURL = "/assets/launch-b25a29efa7a1-staging.min.js";

	constructor(private settingsService: SettingsService, public translate: TranslateService,
		public localization: Localization, private readonly router: Router,
		private readonly sessionService: SessionService, private readonly authService: AuthService) {

		// setTimeout(() => {
		// 	this.settingsService.setTheme('dark');
		// });
		setTheme('bs5');
		localization.detectLanguage();

		this.sessionService.initialized.subscribe((signedIn) => {
			if (signedIn) {
				if (!this.authService.getUser()) {
					this.router.navigate(['/unauthorized']);
				}
			} else {
				this.router.navigate(["/landing"]);
			}
		});

		this.authService.userDataChanged$.subscribe(() => {
			const userDetails = this.authService.getUser();
			if (userDetails) {
				setTimeout(() => {
					if (this.authService.isSuperAdmin()) {
						if (!this.router.url.includes("admin")) {
							this.router.navigate(["/admin"]);
						}
					} else if (this.authService.isAdmin()) {
						if (!this.router.url.includes("admin")) {
							this.router.navigate(["/admin"]);
						}
					} else {
						if (!this.router.url.includes("user")) {
							this.router.navigate(["/user"]);
						}
					}
				});
			}
		});
	}

	ngOnInit(): void {
	}
}
